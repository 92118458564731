<template>
  <div class=container>
      <table width="100%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of Separation Results</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of our separation results. In each case, we mention the separation method we have used. In all cases, the scene image is the sum of the computed direct and global images. However, to bring out the details, for some of the scenes we have brightened the direct and global images by a scale factor (between 1 and 2) with respect to the scene image. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br>
            </td>
          </tr>
        <router-link :to="{path:'/projects/categories/project', query:{cid:'Computational Imaging',pid:'Fast Separation of Direct and Global Images'}}">(Back to Main Project Page) </router-link>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Real vs Fake Objects</strong></td>
                        <td width="53%"><div align="right"><strong>Method : Checkerboard<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/CornRF_Scene.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/CornRF_Scene.png" width="203" height="153" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/CornRF_Direct.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/CornRF_Direct.png" width="203" height="153" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/CornRF_Global.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/CornRF_Global.png" width="203" height="153" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(a)</div></td>
                        <td>&nbsp;</td>
                      </tr>

                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Apple_Scene.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Apple_Scene.png" width="203" height="153" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Apple_Direct.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Apple_Direct.png" width="203" height="153" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Apple_Global.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Apple_Global.png" width="203" height="153" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(b)</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      
                      <tr>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Banana_Scene.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Banana_Scene.png" width="203" height="153" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Banana_Direct.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Banana_Direct.png" width="203" height="153" border="0"></a></td>
                        <td><a href="https://cave.cs.columbia.edu/old/projects/separation/images_lg/Banana_Global.png"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Banana_Global.png" width="203" height="153" border="0"></a></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">(c)</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      
                      <tr>
                        <td width="32%"><div align="center"><strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><strong>Direct</strong></div></td>
                        <td width="33%"><div align="center"><strong>Global</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>Separation results for real and fake objects - (a) Corn, (b) Apple and (c) Banana. 
                           <i> Please click on the images to identify the real and fake objects. </i> 
                           In all the cases there is a distinct difference between the real and fake object in 
                           the direct or the global image or both. For example, the fake corn appears stone-like 
                           when compared to the real one in the direct image, there is almost no subsurface scattering 
                           in the case of the fake apple and the foam structure of the fake banana is clearly evident in both the 
                           direct and global images.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>

          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
}
</style>